import React from "react"

import { CubeIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import { Bee } from "./BeeKit"
import { Pill } from "./BeeKit/Pill"
import { ImageWithFallback } from "~/src/components"
import { ShippingRestrictionsCue } from "~/src/components/ShippingRestrictionsCue"
import { StockIndicator } from "~/src/components/StockIndicator"
import { Format } from "~/src/lib/format"
import { ON_DEMAND_SHIPPED_SEPARATELY_WARNING } from "~/src/lib/shippingRestrictions"
import { Orientation } from "~/src/types/orientation"
import { SelectableType } from "~/src/types/productSelection"

export type ProductTileProps = {
  title: string
  type: SelectableType
  onDemand: boolean
  orientation?: Orientation
  price: number
  customCurrencyUnit?: string
  inventoryCount: number
  prepaid: boolean
  inventoryRequired?: boolean
  className?: string
  subtitle?: string
  imageUrl?: string
  supportedRegions?: string[]
  children?: React.ReactNode
}

function ImageWithFallbackLandscape(props: { imageUrl: string; className?: string }) {
  const { imageUrl, className } = props
  return (
    <ImageWithFallback
      src={imageUrl}
      className={twMerge("flex w-28 h-28 overflow-hidden rounded-lg", className)}
      imgProps={{ className: "h-full w-full object-cover object-center" }}
    />
  )
}

function ImageWithFallbackPortrait(props: { imageUrl: string; className?: string }) {
  const { imageUrl, className } = props
  return (
    <>
      <ImageWithFallback
        src={imageUrl}
        className={twMerge("hidden md:flex justify-center p-4 overflow-hidden h-[154px]", className)}
        imgProps={{ className: "object-cover object-center" }}
      >
        <div className={twMerge("h-full w-full flex items-center justify-center text-gray-400 bg-gray-100")}>
          <CubeIcon className="h-10 w-10 stroke-1" />
        </div>
      </ImageWithFallback>

      <ImageWithFallbackLandscape imageUrl={imageUrl} className={twMerge(className, "md:hidden")} />
    </>
  )
}

function displayCurrency({
  amount,
  prepaid,
  customCurrencyUnit,
}: {
  amount: number
  prepaid?: boolean
  customCurrencyUnit?: string
}): string {
  if (prepaid) return "Prepaid"

  return customCurrencyUnit ? Format.customCurrency(amount, customCurrencyUnit) : Format.usd(amount)
}

/**
 * Product tile in portrait view on desktop. Landscape view on mobile.
 * See ProductTile for details..
 *
 */
function ProductTilePortrait(props: ProductTileProps) {
  const {
    title,
    price,
    customCurrencyUnit,
    type,
    onDemand,
    inventoryCount,
    children,
    className,
    imageUrl = "",
    subtitle = null,
    prepaid = false,
    supportedRegions = [],
    inventoryRequired = true,
  } = props

  return (
    <>
      <div className={twMerge("hidden md:flex flex-col w-[210px] border rounded-lg bg-white pb-4 gap-2", className)}>
        <ImageWithFallbackPortrait imageUrl={imageUrl} />
        <hgroup className="px-4">
          <h1 className="font-medium text-gray-900">{title}</h1>
          {subtitle && <h2 className="text-gray-500 text-sm italic">{subtitle}</h2>}
        </hgroup>

        {supportedRegions?.length ? <ShippingRestrictionsCue className="px-4" regions={supportedRegions} /> : null}
        {onDemand && (
          <Bee.Cue
            className="px-4"
            icon={<CubeIcon className="text-gray-400 h-4 w-4" />}
            tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}
          >
            Ships separately
          </Bee.Cue>
        )}

        <div className="flex mt-auto px-4 py-2 text-sm justify-between">
          {displayCurrency({ amount: price, prepaid: prepaid, customCurrencyUnit: customCurrencyUnit })}
          <StockIndicator quantity={inventoryCount} required={inventoryRequired} type={type} />
        </div>

        <div className="flex w-full md:w-auto gap-8 px-4">
          <div className="flex flex-col w-full">{children}</div>
        </div>
      </div>

      <ProductTileLandscape {...props} className={twMerge("md:hidden", className)} />
    </>
  )
}

/**
 * Product tile in landscape view on desktop and mobile.
 * See ProductTile for details..
 *
 */
function ProductTileLandscape(props: ProductTileProps) {
  const {
    title,
    price,
    customCurrencyUnit,
    type,
    onDemand,
    inventoryCount,
    children,
    className,
    imageUrl = "",
    subtitle = null,
    prepaid = false,
    supportedRegions = [],
    inventoryRequired = true,
  } = props

  return (
    <div
      className={twMerge(
        "flex md:flex-row flex-col w-full justify-between border rounded-lg md:items-center bg-white p-4 gap-4",
        className
      )}
    >
      <div className="flex md:items-center gap-3">
        <ImageWithFallbackLandscape imageUrl={imageUrl} />
        <div className="flex flex-col gap-2">
          <hgroup>
            <h1 className="font-medium leading-6">{title}</h1>
            {subtitle && <h2 className="text-gray-500 text-sm italic">{subtitle}</h2>}
          </hgroup>

          {supportedRegions?.length ? <ShippingRestrictionsCue regions={supportedRegions} /> : null}
          {onDemand && <Bee.Cue tip={ON_DEMAND_SHIPPED_SEPARATELY_WARNING}>Ships separately</Bee.Cue>}

          <span className="text-sm">
            {displayCurrency({ amount: price, prepaid: prepaid, customCurrencyUnit: customCurrencyUnit })}
          </span>

          <Pill className="md:hidden flex gap-2 items-center">
            <StockIndicator quantity={inventoryCount} required={inventoryRequired} type={type} />
          </Pill>
        </div>
      </div>

      <div className="flex w-full md:w-auto gap-8">
        <StockIndicator className="hidden md:flex" quantity={inventoryCount} required={inventoryRequired} type={type} />
        <div className="flex flex-col w-full md:w-auto">{children}</div>
      </div>
    </div>
  )
}

/**
 * Product tile which displays a Product image as well as relevant details.
 * Accepts children for possible actions on the right end of the tile.
 */
export function ProductTile(props: ProductTileProps) {
  const { orientation = Orientation.Portrait, ...restProps } = props

  if (orientation == Orientation.Portrait) {
    return <ProductTilePortrait {...restProps} />
  } else {
    return <ProductTileLandscape {...restProps} />
  }
}
