import React, { forwardRef, useEffect, useRef, useState } from "react"

import { XMarkIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

import emptyProjectCartSrc from "~/images/empty_project_cart.png"
import { Bee } from "~/src/components/BeeKit"
import { RequestTile } from "~/src/components/RequestTile"
import { useInteractOutside } from "~/src/hooks/useInteractOutside"
import { useRequestCart } from "~/src/hooks/useRequestCart"
import { appClient } from "~/src/lib/appClients"
import { dataFlag } from "~/src/lib/jsx"
import { RequestedOrderItem } from "~/src/types/requestedOrderItem"

//region Types
export type RequestCartProps = {
  items?: RequestedOrderItem[]
  onRemoveItem?: (item: RequestedOrderItem) => void
  onSubmit?: () => void
  onClose?: () => void
  className?: string
}
//endregion

//region Component
export function RequestCart() {
  const [ready, setReady] = useState(false)
  const { setItems, items, isDirty, isOpen, dirty, clean, close } = useRequestCart()
  const cartRef = useRef<HTMLDivElement>(null)

  useInteractOutside(cartRef, () => close())

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isOpen) {
      appClient.post("/dashboard/estimate_request/track_event", { event: { name: "view" } })
    }
  }, [isOpen])

  useEffect(() => {
    if (isDirty || (isOpen && !ready)) {
      fetchData()
    }
  }, [isOpen, isDirty])

  const fetchData = async () => {
    const { data } = await appClient(`/dashboard/estimate_request.json`, {
      headers: { Accept: "application/json" },
    })

    setItems(data?.items)
    setReady(true)
    clean()
  }

  const handleRemoveItem = async ({ id }: RequestedOrderItem) => {
    await appClient(`/dashboard/estimate_request/remove.json`, {
      method: "delete",
      params: { id },
      headers: { Accept: "application/json" },
    })

    dirty()
  }

  return (
    <div
      className={twMerge(
        "fixed flex flex-col right-0 top-0 bottom-0 w-[390px] z-60 shadow-lg bg-white transition",
        "ease-in duration-300 translate-x-full data-[open]:translate-x-0",
        "opacity-0 data-[open]:opacity-100"
      )}
      {...dataFlag(ready && isOpen, "open")}
    >
      <RequestCart_ ref={cartRef} items={items} onClose={() => close()} onRemoveItem={handleRemoveItem} />
    </div>
  )
}
//endregion

//region Private
const RequestCart_ = forwardRef<HTMLDivElement, RequestCartProps>(function RequestCart_(props, ref) {
  const { items = [], onRemoveItem, onSubmit, onClose, className } = props

  return (
    <>
      <div className="w-full h-14 flex shrink-0 justify-end bg-white border-b border-gray-200">
        <button
          type="button"
          aria-label="Close Project Cart"
          className="px-3 h-full flex items-center"
          onClick={onClose}
        >
          <XMarkIcon role="img" className="h-6 w-6" />
        </button>
      </div>

      {items.length === 0 ? (
        <EmptyCart className="p-4" />
      ) : (
        <>
          <div
            className={twMerge(
              "flex flex-col h-full gap-4 p-4 bg-white overflow-auto overscroll-contain",
              "[mask-image:linear-gradient(to_top,transparent,black_theme(space.6))]",
              className
            )}
            ref={ref}
          >
            <p>In this estimate request:</p>

            <div className={twMerge("flex flex-col grow gap-4")}>
              {items.map((item: RequestedOrderItem) => {
                return <RequestTile key={item.id} item={item} onRemove={onRemoveItem} />
              })}
            </div>
          </div>

          <a className="flex bg-white shrink-0 px-4 pb-4" href="/dashboard/estimate_request">
            <Bee.Button className="w-full" onClick={onSubmit}>
              Review Request
            </Bee.Button>
          </a>
        </>
      )}
    </>
  )
})

function EmptyCart({ className }: { className?: string }) {
  return (
    <div className={twMerge("flex flex-col gap-4 items-center text-center justify-center h-full", className)}>
      <img src={emptyProjectCartSrc} alt="Empty Project Cart" className="w-32 h-32" />
      <hgroup className="max-w-[300px]">
        <h1 className="text-2xl font-medium">Your project cart is empty</h1>
        <p>Looks like you haven’t added anything to your project request yet.</p>
      </hgroup>
    </div>
  )
}
//endregion
