import React from "react"

import { FolderPlusIcon, HeartIcon, NoSymbolIcon } from "@heroicons/react/24/outline"
import { format } from "date-fns"
import { Link, useSearchParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { ImageSet } from "~/src/components/ImageSet"
import { Format } from "~/src/lib/format"
import { Photo } from "~/src/types/photo"

export type ProductTileProps = {
  id: number
  slug: string
  name: string
  lowPrice: number
  loved: boolean
  hidden: boolean
  photo: Photo
  productUrl: string
  lastOrderedAt?: string
  className?: string
  onLove?: (id: number, slug: string) => void
  onHide?: (id: number, slug: string) => void
  onAddToProjectRequest?: (id: number, slug: string) => void
}

export function ProductTile(props: ProductTileProps) {
  const {
    id,
    slug,
    name,
    lowPrice,
    className,
    loved = false,
    hidden = false,
    photo,
    productUrl,
    lastOrderedAt,
    onLove,
    onHide,
    onAddToProjectRequest,
  } = props

  const [searchParams] = useSearchParams()

  let modalUrl = productUrl
  if (searchParams) modalUrl += `?${searchParams}`

  return (
    <div
      className={twMerge(
        "flex flex-col w-full h-full border rounded-lg bg-white gap-2 relative overflow-hidden",
        className
      )}
    >
      <div className="absolute flex flex-col gap-1 z-10 p-2 top-0 right-0 ">
        <IconKnob
          className={twMerge("ring-red-600", loved ? "bg-pink-200 text-red-600" : "bg-white text-gray-500")}
          onClick={() => onLove?.(id, slug)}
        >
          <HeartIcon className={twMerge("h-4 w-4", loved && "fill-current")} />
        </IconKnob>

        <IconKnob
          className={twMerge("ring-gray-500", hidden ? "bg-gray-500 text-white" : "bg-white text-gray-500")}
          onClick={() => onHide?.(id, slug)}
        >
          <NoSymbolIcon className="h-4 w-4" />
        </IconKnob>
      </div>

      <a href={productUrl} className="p-0 m-0">
        <ImageSet className="h-40 w-full" srcs={[photo.largeUrl, photo.xlargeUrl]} />
      </a>

      <dl className="grow flex flex-col gap-2 px-4 p-1 sm:pt-3 ">
        <dt className="font-medium text-gray-900 leading-snug">{name}</dt>
        <dd className="text-gray-500 text-sm">From {Format.currency(lowPrice)}</dd>
      </dl>

      <div className="justify-bottom">
        {lastOrderedAt && (
          <div className="bg-slate-100 text-center text-xs p-2">
            Last ordered: {format(lastOrderedAt, "yyyy-MM-dd")}
          </div>
        )}

        <Link
          preventScrollReset
          to={modalUrl}
          className="flex border-t p-2 text-blue-600 justify-center"
          onClick={() => onAddToProjectRequest?.(id, slug)}
        >
          <span className="flex gap-2 items-center text-sm max-sm:hidden">
            <FolderPlusIcon className="h-4 w-4" />
            Add to project request
          </span>
          <span className="flex gap-2 items-center text-sm sm:hidden">
            <FolderPlusIcon className="h-4 w-4" />
            Add to project
          </span>
        </Link>
      </div>
    </div>
  )
}

type IconKnobProps = {
  className?: string
  onClick?: () => void
} & JSX.IntrinsicElements["button"]

function IconKnob(props: IconKnobProps) {
  const { className, ...restProps } = props

  return <button className={twMerge("rounded-full p-1 cursor-pointer hover:ring-1 ", className)} {...restProps} />
}
