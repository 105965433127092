import React from "react"

import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { twMerge } from "tailwind-merge"

export type EmptyResultsProps = {
  className?: string
}

export function EmptyResults(props: EmptyResultsProps) {
  const { className } = props

  return (
    <div className={twMerge("flex flex-col justify-center items-center p-8", className)}>
      <MagnifyingGlassIcon className="h-14 w-14 text-gray-300" />
      <h1 className="font-medium text-center pt-4">We couldn’t find what you were looking for</h1>
      <p className="text-sm text-center">Make sure all words are spelled correctly or try different search terms.</p>
    </div>
  )
}
