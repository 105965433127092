import React, { useCallback } from "react"

import BaseForm from "./BaseForm"
import { FormProps } from "./types"
import { Bee } from "~/src/components/BeeKit"
import { SelectPreferenceCollectorInput } from "~/src/components/Solutions/forms/fields/SelectPreferenceCollectorInput"

type BeforeOrAfter = "before" | "after"

type WorkdayNewHireGiftConfig = {
  external_num_days: number | undefined
  external_pg_id: string | undefined
  external_send_before_or_after: BeforeOrAfter | undefined
}

export function WorkdayNewHireGiftForm({
  addValidationErrors,
  initialData,
  isSubmitting,
  title,
  onCancel,
  onSubmit,
}: FormProps<WorkdayNewHireGiftConfig>) {
  const [pgId, setPgId] = React.useState<number | undefined>(() => {
    const idStr = initialData?.external_pg_id
    return idStr == null || idStr.trim() === "" ? 0 : parseInt(idStr)
  })
  const [numDays, setNumDays] = React.useState<number | undefined>(initialData?.external_num_days)
  const [sendBeforeOrAfter, setSendBeforeOrAfter] = React.useState<"before" | "after">(
    initialData?.external_send_before_or_after ?? "before"
  )

  const handleSubmit = () => {
    const errors = {}
    if (pgId == null) errors["external_pg_id"] = "Please select a preferred gift campaign."
    if (numDays == null) errors["external_num_days"] = "Please enter the number of days."

    if (Object.keys(errors).length > 0) {
      addValidationErrors(errors)
    } else {
      onSubmit({
        external_pg_id: pgId?.toString(),
        external_num_days: numDays,
        external_send_before_or_after: sendBeforeOrAfter,
      })
    }
  }

  return (
    <BaseForm disabled={isSubmitting} title={title} onCancel={onCancel} onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4">
        <div className="bg-gray-50 rounded-lg p-4">
          <Bee.Field label="Preferred Gift Campaign">
            <SelectPreferenceCollectorInput required value={pgId} onChange={(newId) => setPgId(newId ?? undefined)} />
          </Bee.Field>
        </div>
        <div className="bg-gray-50 rounded-lg p-4">
          <Bee.Field label="When">
            <div className="flex flex-row items-center gap-2">
              <Bee.Input
                min={0}
                required
                type="number"
                value={numDays}
                onChange={(e) => setNumDays(parseInt(e.target.value))}
              />
              <span>days</span>
              <select
                required
                value={sendBeforeOrAfter}
                onChange={(e) => setSendBeforeOrAfter(e.target.value as BeforeOrAfter)}
              >
                <option value="before">before</option>
                <option value="after">after</option>
              </select>
              <span>employee start date</span>
            </div>
          </Bee.Field>
        </div>
      </div>
    </BaseForm>
  )
}

WorkdayNewHireGiftForm.title = "Workday New Hire Gift Form"
