import React from "react"

import { twMerge } from "tailwind-merge"

export type MainHeadingProps = {
  className?: string
  title: string
  mobileDetailToggle?: React.ReactNode
  actions?: React.ReactNode
  description?: string
}

function MainHeadingDesktop(props: MainHeadingProps) {
  const { className, actions, title, description } = props

  return (
    <hgroup className={twMerge("hidden sm:flex flex-col gap-2 mx-6 my-8", className)}>
      <div className="flex items-start">
        <h1 className="text-gray-900 text-4xl font-medium font-souvenir">{title}</h1>
        {actions ? <div className="ml-auto">{actions}</div> : null}
      </div>
      {description && <p className="text-gray-500 text-lg">{description}</p>}
    </hgroup>
  )
}

function MainHeadingMobile(props: MainHeadingProps) {
  const { className, actions, mobileDetailToggle, title, description } = props

  return (
    <hgroup className={twMerge("sm:hidden mx-6 my-8", className)}>
      <div className="flex flex-col gap-3">
        <h1 className="text-gray-900 text-4xl font-medium font-souvenir">{title}</h1>
        {mobileDetailToggle ? <div>{mobileDetailToggle}</div> : null}
        {actions ? <div>{actions}</div> : null}
      </div>
      {description && <p className="text-gray-500 text-lg">{description}</p>}
    </hgroup>
  )
}

export function MainHeading(props: MainHeadingProps) {
  return (
    <>
      <MainHeadingDesktop {...props} />
      <MainHeadingMobile {...props} />
    </>
  )
}
