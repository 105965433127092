import React, { forwardRef, type PropsWithChildren } from "react"

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline"
import { Link, type LinkProps } from "react-router-dom"
import { twMerge } from "tailwind-merge"

import { dataFlag } from "~/src/lib/jsx"

export type PaginationGroupProps = PropsWithChildren<{ className?: string }>

export type PageLinkProps = {
  current?: boolean
  next?: boolean
  previous?: boolean
  className?: string
  disabled?: boolean
  to?: LinkProps["to"]
} & Omit<LinkProps, "to">

const pageLinkClassName = "h-8 w-8 flex justify-center items-center text-xs font-semibold"

/**
 * A group of pagination links. Use in combination with `PageLink` and `PageGap` children.
 */
export function PaginationGroup(props: PaginationGroupProps) {
  const { className, children } = props

  return (
    <nav className={twMerge("flex gap-0 bg-white border rounded divide-x divide-solid", className)} role="navigation">
      {children}
    </nav>
  )
}

/**
 * A link in a pagination group. Use as a child of `PaginationGroup`.
 */
export const PageLink = forwardRef<HTMLAnchorElement, PageLinkProps>(function PageLink(props, ref) {
  const {
    className,
    disabled,
    current = false,
    next = false,
    previous = false,
    children,
    to = "",
    ...restProps
  } = props

  return (
    <Link
      ref={ref}
      className={twMerge(
        pageLinkClassName,
        "data-[current]:bg-navy-800 data-[current]:text-white",
        "transition-colors cursor-pointer hover:bg-gray-100",
        "data-[disabled]:text-gray-200 data-[disabled]:border-gray-200 data-[disabled]:pointer-events-none",
        (next || previous) && "text-gray-500",
        current && "bg-navy-800 text-white",
        className
      )}
      to={to}
      {...dataFlag(current, "current")}
      {...dataFlag(disabled, "disabled")}
      {...restProps}
    >
      {next ? <ChevronRightIcon className="h-5 w-5" /> : previous ? <ChevronLeftIcon className="h-5 w-5" /> : children}
    </Link>
  )
})

/**
 * A gap in a pagination group. Use as a child of `PaginationGroup`.
 */
export function PageGap() {
  return <div className={twMerge(pageLinkClassName)}>...</div>
}
