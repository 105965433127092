import React from "react"

import { twMerge } from "tailwind-merge"

export type WorkflowHeadingProps = {
  title: string
  subtitle?: string
  children?: React.ReactNode
  className?: string
}

/**
 * WorkflowHeading is a component that displays a title and subtitle in the workflow (QuickSend, BatchOrder, etc.) header.
 *
 * @example
 *    <WorkflowHeading title="Pick products" subtitle="Please pick your products" className="font-sans" />
 * @param props
 *  @param props.title The title of the workflow
 *  @param props.subtitle Optional subtitle for the workflow
 *  @param props.children Optional component(s) to display below the title and subtitle
 *  @param props.className Tailwind classes passed through to top-level div. These will take priority over the default
 * classes. In the example above, the "font-sans" class will override the default "font-souvenir"
 */
export function WorkflowHeading(props: WorkflowHeadingProps) {
  const { title, subtitle, children, className } = props

  return (
    <hgroup className="flex flex-col items-center gap-2">
      <h1 className={twMerge("font-medium text-4xl text-gray-900 text-center font-souvenir", className)}>{title}</h1>
      {subtitle && <p>{subtitle}</p>}
      {children}
    </hgroup>
  )
}
