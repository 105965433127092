import React, { forwardRef } from "react"

import { twMerge } from "tailwind-merge"

import { buttonClassName } from "./sharedClassNames"

export type ButtonProps = { kind?: "primary" | "secondary" } & JSX.IntrinsicElements["button"]

/**
 * Thin wrapper around the native button element forwarding its ref.
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(function Button(props, ref) {
  const { className, kind = "primary", ...restProps } = props

  return <button className={twMerge(buttonClassName(kind), className)} ref={ref} {...restProps} />
})
