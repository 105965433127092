import React, { createContext, PropsWithChildren, ReactNode, useContext, useState } from "react"

import { twMerge } from "tailwind-merge"
import { humanize } from "underscore.string"

import { FieldError } from "./FieldError"
import { arrayWrap } from "~/src/lib/array"

export type FieldProps = PropsWithChildren<{
  className?: string
  defaultErrorMessages?: string | string[]
  errorMessages?: string | string[]
  hint?: ReactNode
  label?: string
  name?: string
  optional?: boolean
}>

export type FieldContext = {
  descendentChange?: () => void
  hasErrors: boolean
}

export const FieldContext = createContext<FieldContext | null>(null)

export function useFieldContext(): FieldContext | null {
  return useContext(FieldContext)
}

/**
 * Container for BeeKit form fields that includes a label and error messages.
 */
export function Field(props: FieldProps) {
  const { label, hint, name, optional = false, children, defaultErrorMessages, errorMessages, className } = props

  const [savedErrorMessages, setSavedErrorMessages] = useState<string[]>(arrayWrap(defaultErrorMessages))

  const currentErrorMessages = errorMessages == null ? savedErrorMessages : arrayWrap(errorMessages)
  const hasErrors = currentErrorMessages.length > 0

  return (
    <div className={twMerge(["flex flex-col", className])}>
      {label && (
        <label className="font-medium">
          {label ?? humanize(name ?? "")}
          {optional && <span className="font-normal text-sm text-gray-500"> - Optional</span>}
        </label>
      )}

      {typeof hint === "string" ? <p className="text-sm text-gray-500 mb-1">{hint}</p> : <>{hint}</>}

      <FieldContext.Provider
        value={{
          descendentChange() {
            setSavedErrorMessages([])
          },
          hasErrors,
        }}
      >
        {children}
      </FieldContext.Provider>

      <div className={twMerge("flex flex-col", !hasErrors && "invisible")}>
        {hasErrors &&
          currentErrorMessages.map((errorMessage) => <FieldError key={errorMessage} message={errorMessage} />)}
        {!hasErrors && <FieldError message="&nbsp;" />}
      </div>
    </div>
  )
}
