import React from "react"

import { ExclamationTriangleIcon } from "@heroicons/react/20/solid"

import { Bee } from "../BeeKit"

export type LowInventoryNofitierProps = {
  editSelectionUrl: string
  inventoryCount: number
  lowInventoryThreshold: number
}

export function LowInventoryNofitier(props: LowInventoryNofitierProps) {
  const { editSelectionUrl, inventoryCount, lowInventoryThreshold } = props
  if (inventoryCount <= 0) {
    return (
      <Bee.Pill className="bg-red-100 text-red-700 gap-1 px-1.5 py-0.5">
        <ExclamationTriangleIcon className="h-4 w-4" />
        <p>Out of Stock</p>
      </Bee.Pill>
    )
  } else if (inventoryCount < lowInventoryThreshold) {
    return (
      <div className="flex flex-col gap-1 text-[10px]">
        <Bee.Pill className="bg-yellow-100 text-yellow-700 gap-1 px-1.5 py-0.5">
          <ExclamationTriangleIcon className="h-4 w-4" />
          <p>Low Inventory</p>
        </Bee.Pill>
        <p className="text-gray-500 leading-4">
          Since this item is low in inventory, there&apos;s a possibility your recipient won&apos;t be able to redeem
          their gift if it goes out of stock. Replace this item or keep a close eye on inventory during the redemption
          period to avoid any hiccups.
          <br />
          <a className="underline" href={editSelectionUrl}>
            Edit product selections
          </a>
        </p>
      </div>
    )
  }
}
