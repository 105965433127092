import * as React from "react"

import { twMerge } from "tailwind-merge"

type LayoutComponentProps = {
  children: React.ReactNode
  className?: string
}

function SectionFn({ children, className }: LayoutComponentProps) {
  return <section className={twMerge("py-6 w-full", className)}>{children}</section>
}

function Title({ children, className }: LayoutComponentProps) {
  return (
    <h3 className={twMerge("text-center text-gray-400 text-base font-bold pb-4 uppercase", className)}>{children}</h3>
  )
}

function Content({ children, className }: LayoutComponentProps) {
  return (
    <div
      className={twMerge(
        "p-8 bg-gray-50 rounded-lg flex-col justify-start items-center gap-4 inline-flex w-full",
        className
      )}
    >
      {children}
    </div>
  )
}

export const Section = Object.assign(SectionFn, { Title, Content })
