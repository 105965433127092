import React, { Fragment, useContext, useMemo, useRef, useState } from "react"

import type { Placement } from "@floating-ui/react"
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
} from "@floating-ui/react"
import { Transition } from "@headlessui/react"
import { twMerge } from "tailwind-merge"

export type TooltipOptions = {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function useTip(options: TooltipOptions) {
  const {
    initialOpen = false,
    placement = "top",
    open: controlledOpen,
    onOpenChange: setControlledOpen,
  } = options ?? {}
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const arrowRef = useRef(null)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5,
      }),
      shift({ padding: 5 }),
      arrow({
        element: arrowRef,
      }),
    ],
  })

  const context = data.context
  const hover = useHover(context, { move: false, enabled: controlledOpen == null })
  const focus = useFocus(context, { enabled: controlledOpen == null })
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: "tooltip" })
  const interactions = useInteractions([hover, focus, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      arrowRef,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTip> | null

const TipContext = React.createContext<ContextType>(null)

export function useTipContext() {
  const context = useContext(TipContext)

  if (context == null) {
    throw new Error("Tip components must be wrapped in <Tip />")
  }

  return context
}

export type TipProps = { children: React.ReactNode } & TooltipOptions

/**
 * The Tip context provider. `TipTrigger`, `TipBubble` and `TipContent` must be a descendant of this component.
 * Based on https://floating-ui.com/. Implementation largely lifted from https://floating-ui.com/docs/tooltip.
 * @param props
 * @param props.children
 * @param props.initialOpen
 * @param props.placement
 * @param props.open
 * @param props.onOpenChange
 * @returns
 */
export function Tip(props: TipProps) {
  const { children, ...options } = props
  const tooltip = useTip(options)
  return <TipContext.Provider value={tooltip}>{children}</TipContext.Provider>
}

export type TipTriggerProps = React.HTMLProps<HTMLElement> & { asChild?: boolean }

/**
 * The trigger of the tooltip. This component must be a descendant of `Tip`.
 * Surrounds the element that will be the focus of the tooltip triggering on hover.
 */
export const TipTrigger = React.forwardRef<HTMLElement, TipTriggerProps>(function TipTrigger(
  { children, asChild = false, ...props },
  propRef
) {
  const context = useTipContext()
  const ref = useMergeRefs([context.refs.setReference, propRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        "data-state": context.open ? "open" : "closed",
      })
    )
  }

  const restProps = { ...(context.open ? { "data-tip-open": "" } : {}), ...context.getReferenceProps(props) }

  return (
    <div
      className="block"
      ref={ref}
      // The user can style the trigger based on the state
      {...restProps}
    >
      {children}
    </div>
  )
})

export type TipContentProps = JSX.IntrinsicElements["div"]

/**
 * The unstyled floating content of the tooltip. This component must be a descendant of `Tip`.
 * @param props
 * @param props.children The content of the tooltip.
 */
export const TipContent = React.forwardRef<HTMLDivElement, TipContentProps>(function TipContent(
  props: TipContentProps,
  propRef
) {
  const { style, ...restProps } = props
  const context = useTipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open) return null

  return (
    <div
      ref={ref}
      style={{
        ...context.floatingStyles,
        ...style,
      }}
      {...context.getFloatingProps(restProps)}
    />
  )
})

/**
 * The styled floating content of the tooltip. This component must be a descendant of `Tip`.
 * Made to appear as a bubble with an optional arrow pointing to the trigger.
 * @param props
 * @param props.children The content of the tooltip.
 */
export const TipBubble = React.forwardRef<HTMLDivElement, TipContentProps & { showArrow: boolean }>(function TipBubble(
  props: TipContentProps & { showArrow: boolean },
  propRef
) {
  const { className, children, showArrow, ...restProps } = props
  const context = useTipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  return (
    <div
      ref={ref}
      data-tip-tooltip=""
      className="z-60"
      style={{
        ...context.floatingStyles,
      }}
      {...context.getFloatingProps(restProps)}
    >
      <Transition
        show={context.open}
        as={Fragment}
        enter="transition ease-in duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className={twMerge("bg-white relative rounded-lg shadow py-2 px-2.5", className)}>
          {children}
          {showArrow ? (
            <Fragment>
              <FloatingArrow
                ref={context.arrowRef}
                width={24}
                height={12}
                className="fill-white [&>path:last-of-type]:stroke-white"
                context={context.context}
              />
              <FloatingArrow
                ref={context.arrowRef}
                width={24}
                height={12}
                className="fill-white [&>path:last-of-type]:stroke-white drop-shadow -z-10"
                context={context.context}
              />
            </Fragment>
          ) : null}
        </div>
      </Transition>
    </div>
  )
})
