import React from "react"

import { twMerge } from "tailwind-merge"

export type FieldErrorProps = {
  label?: string
  message?: string
  className?: string
}

export function FieldError(props: FieldErrorProps) {
  const { className, label, message } = props

  if (message == null) return <></>

  return (
    <small className={twMerge("text-sm text-red-500 mr-auto", className)}>
      {[label, message].filter(Boolean).join(" ")}
    </small>
  )
}
