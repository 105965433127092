import React, { ReactNode, useEffect, useState } from "react"

import { ArrowLongLeftIcon, ArrowLongRightIcon } from "@heroicons/react/24/outline"
import { useMeasure } from "react-use"
import { twMerge } from "tailwind-merge"

export type CarouselNavProps = {
  className?: string
  containerClassName?: string
  scrollOffset?: number
  children: ReactNode
}

/**
 * A BeeKit horizontal container with elements scrollable by width-sized windows via arrow buttons.
 */
export function CarouselNav(props: CarouselNavProps) {
  const { containerClassName, className, children, scrollOffset = 28 } = props
  const [nav, setNav] = useState<HTMLDivElement | null>(null)
  const [shouldShowLeft, setShouldShowLeft] = useState(false)
  const [shouldShowRight, setShouldShowRight] = useState(false)
  const [measureRef, { width: navWidth, height: navHeight }] = useMeasure()

  useEffect(() => {
    updateShowScroll()
  }, [nav, navWidth, navHeight])

  const updateShowScroll = () => {
    if (nav != null) {
      setShouldShowLeft(nav.scrollLeft > 0)
      setShouldShowRight(Math.ceil(nav.scrollLeft) < nav.scrollWidth - nav.clientWidth)
    }
  }

  const handleScrollLeft = () => {
    if (nav != null) {
      const navBounds = nav.getBoundingClientRect()
      const prevWindowLeft = navBounds.x - navBounds.width + scrollOffset
      const children = Array.from(nav.children)

      const validChildren = children
        .map((el: HTMLElement) => el.getBoundingClientRect())
        .filter((bounds) => bounds.x >= prevWindowLeft)
        .sort((a, b) => a.x + a.width - (b.x + b.width))
      const nextChild = validChildren.at(0)

      nextChild
        ? nav.scrollTo({ left: nav.scrollLeft - (navBounds.x - nextChild.x) - scrollOffset, behavior: "smooth" })
        : nav.scrollTo({ left: 0, behavior: "smooth" })
    }
  }

  const handleScrollRight = () => {
    if (nav != null) {
      const navBounds = nav.getBoundingClientRect()
      const children = Array.from(nav.children)
      const nextWindowLeft = navBounds.x + navBounds.width - scrollOffset
      const validChildren = children
        .map((el: HTMLElement) => el.getBoundingClientRect())
        .filter((bounds) => bounds.x > navBounds.x + scrollOffset && bounds.x + bounds.width >= nextWindowLeft)
        .sort((a, b) => a.x + a.width - (b.x + b.width))
      const nextChild = validChildren.at(0)
      nextChild
        ? nav.scrollTo({ left: nav.scrollLeft + (nextChild.x - navBounds.x) - scrollOffset, behavior: "smooth" })
        : nav.scrollTo({ left: nav.scrollWidth - nav.clientWidth, behavior: "smooth" })
    }
  }

  const onReverseTab = (e: React.KeyboardEvent) => {
    if (e.code == "Tab" && e.shiftKey) {
      e.preventDefault()
      handleScrollLeft()
    }
  }

  const onTab = (e: React.KeyboardEvent) => {
    if (e.code == "Tab" && !e.shiftKey) {
      e.preventDefault()
      handleScrollRight()
    }
  }

  const leftOnlyGradient = "[mask-image:linear-gradient(to_right,transparent_16px,black_32px)]"
  const rightOnlyGradient = "[mask-image:linear-gradient(to_left,transparent_16px,black_32px)]"
  const bothGradient =
    "[mask-image:linear-gradient(to_right,transparent_16px,black_32px,black_calc(100%-32px),transparent_calc(100%-16px))]"

  let gradient = bothGradient
  if (shouldShowLeft && !shouldShowRight) {
    gradient = leftOnlyGradient
  } else if (!shouldShowLeft && shouldShowRight) {
    gradient = rightOnlyGradient
  } else if (!shouldShowLeft && !shouldShowRight) {
    gradient = ""
  }

  return (
    <nav className={twMerge("flex flex-row justify-center items-center text-nowrap", containerClassName)}>
      {shouldShowLeft ? (
        <button type="button" className="w-6 -mr-6 z-10" onClick={handleScrollLeft} onKeyDown={onReverseTab}>
          <ArrowLongLeftIcon className="w-6 h-6"></ArrowLongLeftIcon>
        </button>
      ) : null}
      <div
        className={twMerge("flex flex-row overflow-x-hidden", gradient, className)}
        onScroll={updateShowScroll}
        ref={(el) => {
          setNav(el)
          if (el) {
            measureRef(el)
          }
        }}
      >
        {children}
      </div>
      {shouldShowRight ? (
        <button type="button" className="w-6 -ml-6 z-10" onClick={handleScrollRight} onKeyDown={onTab}>
          <ArrowLongRightIcon className="w-6 h-6"></ArrowLongRightIcon>
        </button>
      ) : null}
    </nav>
  )
}
