import React from "react"

import { createRoot, Root } from "react-dom/client"

import { isNil, presence } from "~/src/lib/any"
import { camelCaseKeys } from "~/src/lib/object"
import { getComponent } from "~/src/lib/reactComponentRegistry"

/**
 * Renders a React component named after value passed to the `data-name` attribute.
 * Component must have been registered with `registerComponents`. Props are gathered
 * from a script tag with an `id` matching `data-props-id`.
 *
 * This custom element is meant to be used with the `react_component` application helper, and
 * not by itself.
 */
export class ReactComponent extends HTMLElement {
  reactRoot?: Root

  constructor() {
    super()
  }

  connectedCallback() {
    this.renderComponent()
  }

  disconnectedCallback() {
    this.reactRoot?.unmount()
  }

  loadProps(): Record<string, any> {
    const { propsId } = this.dataset
    const camelCaseProps = this.getAttribute("camel-case-props")?.toLowerCase() !== "false"

    if (propsId) {
      const propsScriptNode = this.parentNode?.querySelector(`#${propsId}`) ?? document.getElementById(propsId)

      if (propsScriptNode instanceof HTMLScriptElement) {
        const propsText = presence(propsScriptNode.innerText)

        if (isNil(propsText)) return {}

        return camelCaseProps ? camelCaseKeys(JSON.parse(propsText)) : JSON.parse(propsText)
      }
    }

    return {}
  }

  renderComponent() {
    const { name } = this.dataset

    if (typeof name !== "string") return

    const props = this.loadProps()
    const Component = getComponent(name)

    this.reactRoot = createRoot(this)
    this.reactRoot.render(<Component {...props} />)
  }
}
