import React, { PropsWithChildren, useCallback, useEffect, useState } from "react"

import { Transition } from "@headlessui/react"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import { useDrag } from "@use-gesture/react"
import { twMerge } from "tailwind-merge"

import { Icon } from "~/src/components"

type SlideDirection = "left" | "right"

function getIndexTrio(currentIndex: number, maxIndex: number) {
  const nextIndex = currentIndex >= maxIndex ? 0 : currentIndex + 1
  const prevIndex = currentIndex <= 0 ? maxIndex : currentIndex - 1
  return [prevIndex, currentIndex, nextIndex]
}

type CarouselProps = PropsWithChildren<{
  className: string
  value?: number
  defaultValue?: number
  onValueChange?: (newValue: number) => void
  showDots?: boolean
  showArrows?: boolean
}>

/**
 * Simple multi-slide carousel. Takes children that represent the content to show each slide. With 0 or 1 child, no carousel ui is displayed. With two or more children, a dot progress bar shows the current child, and clicking the carousel advances it.
 *
 * @param props.children The children to display per-slide in the carousel
 */
export function Carousel(props: CarouselProps) {
  const { children, className, value, defaultValue, onValueChange, showDots = true, showArrows = false } = props
  const isControlled = value != null
  const childList = React.Children.toArray(children)
  const maxIndex = childList.length - 1
  const initialValue = defaultValue != null ? defaultValue : isControlled ? value : 0

  const [indexTrio, setIndexTrio] = useState(getIndexTrio(initialValue, maxIndex))
  const [isSliding, setIsSliding] = useState(false)
  const [slideInIndex, setSlideInIndex] = useState(indexTrio[1])
  const [slideOutIndex, setSlideOutIndex] = useState(indexTrio[1])
  const [slideDirection, setSlideDirection] = useState<SlideDirection>("right")

  const transitionTo = useCallback(
    (targetIndex: number) => {
      if (targetIndex === indexTrio[1]) return
      if (targetIndex === indexTrio[0]) {
        setSlideDirection("left")
      } else if (targetIndex === indexTrio[2]) {
        setSlideDirection("right")
      } else if (targetIndex < indexTrio[1]) {
        setSlideDirection("left")
      } else {
        setSlideDirection("right")
      }

      const newTrio = getIndexTrio(targetIndex, maxIndex)
      setIsSliding(true)
      setSlideInIndex(newTrio[1])
      setSlideOutIndex(indexTrio[1])
      setIndexTrio(newTrio)
    },
    [indexTrio]
  )

  useEffect(() => {
    if (isControlled) {
      transitionTo(value)
    }
  }, [value, transitionTo])

  useEffect(() => {
    const childList = React.Children.toArray(children)
    const maxIndex = childList.length - 1
    setIndexTrio(getIndexTrio(indexTrio[1], maxIndex))
  }, [children])

  const endTransition = () => {
    setIsSliding(false)
  }

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isSliding) {
      const { clientX, currentTarget } = e
      const clickPosition = clientX - currentTarget.getBoundingClientRect().left
      const nextIndex = clickPosition < currentTarget.clientWidth / 2 ? indexTrio[0] : indexTrio[2]

      isControlled ? onValueChange?.(nextIndex) : transitionTo(nextIndex)
    }
  }

  const handleRight = () => {
    if (!isSliding) {
      isControlled ? onValueChange?.(indexTrio[2]) : transitionTo(indexTrio[2])
    }
  }

  const handleLeft = () => {
    if (!isSliding) {
      isControlled ? onValueChange?.(indexTrio[0]) : transitionTo(indexTrio[0])
    }
  }

  const bindSwipe = useDrag(({ swipe }) => {
    const [swipeX] = swipe
    if (swipeX == -1) {
      handleRight()
    }
    if (swipeX == 1) {
      handleLeft()
    }
  })

  if (childList.length <= 1) {
    return <div className={className}>{children}</div>
  }

  return (
    <div className={twMerge("flex flex-col w-full h-full cursor-pointer", className)}>
      <div
        className={twMerge("grid w-full h-full overflow-hidden", showDots ? "basis-5/6" : "")}
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "ArrowLeft") {
            return handleLeft()
          }
          if (e.key === "ArrowRight") {
            return handleRight()
          }
        }}
        {...bindSwipe()}
      >
        {childList.map((child, index) => (
          <div key={index} className={indexTrio.includes(index) ? "col-span-full row-span-full" : "hidden"}>
            <Transition
              show={indexTrio[1] === index}
              enter={isSliding && slideInIndex === index ? "transition duration-500" : ""}
              enterFrom={slideDirection === "right" ? "translate-x-full" : "-translate-x-full"}
              leave={isSliding && slideOutIndex === index ? "transition duration-500" : ""}
              leaveTo={slideDirection === "right" ? "-translate-x-full" : "translate-x-full"}
              afterLeave={endTransition}
            >
              <div className="flex justify-center">{child}</div>
            </Transition>
          </div>
        ))}
        {showArrows ? (
          <div className="col-span-full row-span-full flex flex-col justify-center z-10">
            <div className="flex justify-between px-2 h-[5%] min-h-6">
              <ChevronLeftIcon className="rounded-lg bg-white text-gray-400" />
              <ChevronRightIcon className="rounded-lg bg-white text-gray-400" />
            </div>
          </div>
        ) : null}
      </div>
      {showDots ? (
        <div className="basis-1/6 flex justify-center items-end w-full gap-2 select-none">
          {childList.map((_, index) => (
            <div
              key={index}
              onClick={() => {
                if (!isSliding) {
                  isControlled ? onValueChange?.(index) : transitionTo(index)
                }
              }}
            >
              <Icon.Dot className={index === indexTrio[1] ? "text-navy-800" : "text-gray-200"} />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  )
}
