import React, { CSSProperties, InputHTMLAttributes, useState } from "react"

import { twMerge } from "tailwind-merge"

import { useFieldContext } from "./Field"
import { PathBuilder } from "~/src/lib/iname"

type InputProps = { name?: PathBuilder | string; defaultValue?: number; value?: number } & Omit<
  JSX.IntrinsicElements["input"],
  "name" | "defaultValue" | "value"
>

export type RangeInputProps = {
  className?: string
  left?: InputProps
  right?: InputProps
  min?: number
  max?: number
  step?: number
  onChange?: (value: [number, number]) => void
}

const inputClassName = twMerge(
  "absolute",
  "w-full",
  "pointer-events-none",
  "appearance-none",
  "h-full",
  "opacity-0",
  "z-20",
  "p-0",
  "range-track:appearance-none",
  "range-track:bg-transparent",
  "range-track:border-transparent",
  "range-thumb:appearance-none",
  "range-thumb:pointer-events-auto",
  "range-thumb:w-[var(--slider-thumb-size)]",
  "range-thumb:h-[var(--slider-thumb-size)]",
  "range-thumb:rounded-none",
  "range-thumb:border-0",
  "range-thumb:cursor-grab",
  "range-thumb:bg-transparent",
  "range-thumb:active:cursor-grabbing"
)

const controlClassName = twMerge(
  "absolute",
  "w-[var(--slider-thumb-size)]",
  "h-[var(--slider-thumb-size)]",
  "rounded-full",
  "bg-sky-400",
  "top-[50%]",
  "ml-[calc(var(--slider-thumb-size)/-2)]",
  "-translate-y-[50%]",
  "z-10"
)

const inputWrapperClassName = twMerge(
  "absolute",
  "w-[calc(100%+var(--slider-thumb-size))]",
  "my-0",
  "mx-[calc(var(--slider-thumb-size)/-2)]",
  "h-[var(--slider-thumb-size)]"
)

/**
 * A Beekit range input element. It is composed of two `input[type=range]` elements, left & right, that represent the minimum and maximum values.
 * The `name` of each input must be set individually via `left` and `right` props.
 *
 * @example
 * ```
 * <Bee.RangeInput left={{ name: iname().hz.min }} right={{ name: iname().hz.max }} min={0} max={20000} step={10} />
 * ```
 */
export function RangeInput(props: RangeInputProps) {
  const { className, min = 0, max = 100, step = 1, left = {}, right = {}, onChange } = props
  const { defaultValue: leftDefaultValue, ...restLeft } = left
  const { defaultValue: rightDefaultValue, ...restRight } = right

  const [leftValueState, setLeftValue] = useState(leftDefaultValue ?? min)
  const [rightValueState, setRightValue] = useState(rightDefaultValue ?? max)
  const fieldContext = useFieldContext()

  const leftValue = "value" in left ? (left.value ?? min) : leftValueState
  const rightValue = "value" in right ? (right.value ?? max) : rightValueState

  const minPos = ((leftValue - min) / (max - min)) * 100
  const maxPos = ((rightValue - min) / (max - min)) * 100

  if ("value" in left && "defaultValue" in left) {
    console.warn("Both 'value' and 'defaultValue' are set on the left input. 'value' will be used.")
  }

  if ("value" in right && "defaultValue" in right) {
    console.warn("Both 'value' and 'defaultValue' are set on the right input. 'value' will be used.")
  }

  const handleLeftChange: InputHTMLAttributes<HTMLInputElement>["onChange"] = (event) => {
    event.preventDefault()
    const newLeftValue = Math.min(+event.target.value, rightValue - step)

    if (!("value" in left)) setLeftValue(newLeftValue)

    fieldContext?.descendentChange?.()
    onChange?.([newLeftValue, rightValue])
    restLeft.onChange?.(event)
  }

  const handleRightChange: InputHTMLAttributes<HTMLInputElement>["onChange"] = (event) => {
    event.preventDefault()
    const newRightValue = Math.max(+event.target.value, leftValue + step)

    if (!("value" in right)) setRightValue(newRightValue)

    fieldContext?.descendentChange?.()
    onChange?.([leftValue, newRightValue])
    restRight.onChange?.(event)
  }

  const leftInputProps = {
    ...restLeft,
    name: restLeft.name?.toString(),
    className: twMerge(inputClassName, restLeft.className),
    type: "range",
    value: leftValue,
    min,
    max,
    step,
    onChange: handleLeftChange,
  }

  const rightInputProps = {
    ...restRight,
    name: restRight.name?.toString(),
    className: twMerge(inputClassName, restRight.className),
    type: "range",
    value: rightValue,
    min,
    max,
    step,
    onChange: handleRightChange,
  }

  return (
    <div
      className={twMerge(
        "relative",
        "flex",
        "items-center",
        "w-full",
        "mx-[calc(var(--slider-thumb-size)/2)]",
        "h-[var(--slider-thumb-size)]",
        "min-w-[calc(var(--slider-thumb-size)*10)]",
        className
      )}
      style={{ "--slider-thumb-size": "0.8125rem" } as CSSProperties}
    >
      <div className={inputWrapperClassName}>
        <input {...leftInputProps} />
        <input {...rightInputProps} />
      </div>

      <div className="absolute w-full h-[var(--slider-thumb-size)]">
        <div className={controlClassName} style={{ left: `${minPos}%` }} />
        <div className="absolute w-full top-[50%] -translate-y-[50%] h-[6px] rounded-full bg-gray-300">
          <div className="absolute h-full bg-sky-400" style={{ left: `${minPos}%`, right: `${100 - maxPos}%` }} />
        </div>
        <div className={controlClassName} style={{ left: `${maxPos}%` }} />
      </div>
    </div>
  )
}
