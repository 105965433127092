import React, { PropsWithChildren } from "react"

import { ImageWithFallback } from "~/src/components"
import { isNil, isntNil, isPresent } from "~/src/lib/any"
import { Format } from "~/src/lib/format"
import { QuickSendSelectionKind } from "~/src/types/preferredGift"
import type { StoreProduct, Variant } from "~/src/types/productSelection"

export type SummaryStoreProductProps = {
  storeProduct: StoreProduct
  variant?: Variant
  onSelection?: (storeProduct: StoreProduct, selectionType: QuickSendSelectionKind, selectedVariant?: Variant) => void
}

export function QuickSendSummaryStoreProduct(props: PropsWithChildren<SummaryStoreProductProps>) {
  const { storeProduct, variant, onSelection, children } = props
  const { name, displayPrice, imageUrl, prepaid } = storeProduct

  return (
    <div className="flex gap-2">
      <div className="h-14 w-14">
        <ImageWithFallback
          src={imageUrl}
          className={"flex w-14 h-14 rounded-lg"}
          imgProps={{ className: "h-14 w-14" }}
        />
      </div>
      <div className="flex gap-2 justify-between w-full">
        <div className="flex flex-col gap-2 items-start">
          <div className="flex flex-col gap-1 items-start">
            <p className="font-medium text-sm text-gray-900">{name}</p>
            {renderVariant(variant)}
          </div>
          {children}
          {isntNil(onSelection) ? (
            <button
              type="button"
              className="text-blue-600 text-xs md:flex hidden"
              onClick={() => onSelection(storeProduct, QuickSendSelectionKind.None, variant)}
            >
              Remove
            </button>
          ) : (
            <></>
          )}
        </div>
        <p className="text-gray-600 text-sm">
          {displayCurrency({ amount: displayPrice, prepaid, customCurrencyUnit: storeProduct.customCurrencyUnit })}
        </p>
      </div>
    </div>
  )
}

function displayCurrency({
  amount,
  prepaid,
  customCurrencyUnit,
}: {
  amount: number
  prepaid?: boolean
  customCurrencyUnit?: string
}): string {
  if (prepaid) return "Prepaid"

  return customCurrencyUnit ? Format.customCurrency(amount, customCurrencyUnit) : Format.usd(amount)
}

function renderVariant(variant?: Variant) {
  if (isNil(variant)) {
    return null
  }

  const attributes: string[] = [variant.colorName, variant.size, variant.flavor].filter(isntNil).filter(isPresent)
  if (attributes.length == 0) {
    return null
  }
  return (
    <ul>
      {attributes.map((attribute, i) => (
        <li key={`${i}-${attribute}`} className="text-xs capitalize">
          {attribute}
        </li>
      ))}
    </ul>
  )
}
